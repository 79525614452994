import request from '@/utils/request'

// 登录方法
export function login(username, code) {
    const data = {
        username,
        code
    }
    return request({
        url: '/api/login/userLogin',
        headers: {
            isToken: false,
            repeatSubmit: false
        },
        method: 'post',
        data: data
    })
}

// 获取验证码
export function getCodeImg(username, captchaVerifyParam) {
    const data = {
        username,
        captchaVerifyParam
    }
    return request({
        url: '/api/login/sendSMS',
        method: 'post',
        data: data
    })
}

// 验证身份信息
export function validate(data) {
    return request({
        url: '/api/loan/validate',
        method: 'post',
        data: data
    })
}

// 信贷提交
export function submit(data) {
    return request({
        url: '/api/loan/submit',
        method: 'post',
        data: data
    })
}

// 信贷提交
export function update(data) {
    return request({
        url: '/api/loan/update',
        method: 'post',
        data: data
    })
}

// 查询二维码
export function getImage(query) {
    return request({
        url: '/api/enterprise/config/' + query,
        method: 'get',
    })
}

// 查询进度
export function getLoanAppDetail() {
    return request({
        url: '/api/loan/getLoanAppDetail',
        method: 'get',
    })
}


// 查询当前申请进度
export function getProcess(query) {
    return request({
        url: '/api/users/checkUserSteps/' + query,
        method: 'get',
    })
}
