<template>
  <div class="app-container">
    <img v-if="myImg" :src="myImg" style="height: 35px;">
    <div class="background-div">
      <div style="">
        <div class="title">期限长 ｜ 额度高 ｜ 利率低 ｜ 提款快 ｜ 还款活</div>
        <el-card shadow="never">
          <div class="info" @click="openInfo">详情</div>
          <div class="card-top">最高可获批额度</div>
          <div class="card-center">600,000.00<span style="font-size: 23px">元</span></div>
          <div class="card-bottom">企业员工年利率最低可至2.9%</div>
        </el-card>
        <div class="apply">申请步骤</div>
        <div class="status">
          <img src="../assets/images/status1.png" style="width: 100%">
        </div>
      </div>
    </div>
    <el-form ref="loginForm" label-width="65px" :rules="rules" :model="form" class="login-form">
      <el-form-item label-width="0">
        <el-alert
            title="输入您本人实名的手机号，用于办理贷款业务。"
            type="warning"
            :closable="false"
            show-icon>
        </el-alert>
      </el-form-item>
      <el-form-item label="手机号" prop="username">

        <el-input
            v-model="form.username"
            type="text"
            auto-complete="off"
            placeholder="本人实名手机号"
        >
        </el-input>
      </el-form-item>
      <div id="captcha-element"></div>
    </el-form>
    <div class="fixed-bottom flex-center">
      <el-button id="captcha-button" type="primary" round style="width: 300px" @click="getCode">
        获取验证码
      </el-button>
    </div>
    <el-dialog :visible.sync="open" width="80%">
      <div class="flex-column-center">
        <img src="../assets/images/vip.png">
        <div style="margin-top: 20px;">平台会根据您的实际情况智能</div>
        <div>匹配市面上高额度、低利息的</div>
        <div>银行贷款产品。</div>
      </div>
      <div class="flex-center" style="margin: 20px;">
        <el-button @click="open = false" class="custom-button">知道了</el-button>
      </div>
    </el-dialog>
    <!-- 新增验证码弹窗 -->
    <el-dialog
        :visible.sync="codeDialogVisible"
        width="80%"
        custom-class="code-dialog"
        :show-close="false"
        :close-on-click-modal="false"
    >
      <div class="code-dialog-content">
        <div class="code-title">请输入验证码</div>
        <div class="code-input">
          <input
              ref="codeInput"
              v-model="fullCode"
              @input="handleFullCodeInput"
              maxlength="6"
              inputmode="numeric"
              pattern="[0-9]*"
          >
        </div>
        <div class="code-display">
          <span v-for="(digit, index) in displayCode" :key="index">{{ digit }}</span>
        </div>
        <div class="code-tip">请输入6位验证码</div>
        <el-button type="primary" :loading="loading" @click="handleLogin" class="code-submit-btn">
          <span v-if="!loading">下一步</span>
          <span v-else>登录中...</span>
        </el-button>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {getCodeImg, getLoanAppDetail, getProcess} from "@/api/api"
import store from "@/store";

export default {
  name: "login",
  data() {
    return {
      form: {
        username: null,
        code: null,
        captchaVerifyParam: null
      },
      rules: {
        username: [
          {required: true, message: '请输入本人实名手机号', trigger: 'blur'},
          {pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur'}
        ],
        code: [
          {required: true, message: '请输入短信验证码', trigger: 'blur'}
        ]
      },
      open: false,
      loading: false,
      captcha: null,
      type: null,
      myImg: store.getters.img,
      countdown: 0, // 倒计时秒数
      intervalId: null, // 计时器ID，用于清除计时器
      cardStatus: false,
      codeDialogVisible: false,
      codeInputs: ['', '', '', '', '', ''],
      fullCode: '',
      displayCode: ['', '', '', '', '', ''],
      firstLoading: null
    }
  },
  created() {
    this.getTypeFromUrl();
  },
  beforeDestroy() {
    // 组件销毁前清除计时器
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  mounted() {
    window.initAliyunCaptcha({
      SceneId: '1b37xt2h', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: '1kre14', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: 'embed', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: '#captcha-element', //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: '#captcha-button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 100,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    });
    this.firstLoading = this.$loading({
      lock: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    setTimeout(() => {
      this.checkProcessStatus();
    }, 2000)


  },
  methods: {

    handleFullCodeInput() {
      // 过滤非数字字符
      this.fullCode = this.fullCode.replace(/\D/g, '').slice(0, 6);

      // 更新显示用的数组
      this.displayCode = [...this.fullCode.padEnd(6, ' ')];

      // 更新 form.code
      this.form.code = this.fullCode;

      // 如果输入完成，自动提交
      if (this.fullCode.length === 6) {
        this.$nextTick(() => {
          this.handleLogin();
        });
      }
    },
    // 显示验证码弹窗
    showCodeDialog() {
      if (this.form.captchaVerifyParam) {
        this.$refs.loginForm.validate(valid => {
          if (valid) {
            this.codeDialogVisible = true;
          } else {
            this.$message.error('请填写正确的手机号');
          }
        });
      }
    },
    checkProcessStatus() {
      if (this.type) {
        getProcess(this.type).then(res => {
          if (res.code === 200 && res.url && !window.location.href.includes(res.url)) {
            this.$router.push("/" + res.url).catch(() => {
            });
          } else {
            this.firstLoading.close();
          }
        })
      }
    },
    getTypeFromUrl() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      this.type = urlParams.get('type');
      window.localStorage.setItem("entId", this.type);
      this.$store.dispatch("getImgInfo", this.type).then(res => {
        this.myImg = res;
      })
    },
    getInstance(instance) {
      this.captcha = instance;
    },
    // 验证通过后调用
    onBizResultCallback(bizResult) {
      console.log("onBizResultCallback" + bizResult)
      if (bizResult === true) {
        // 如果业务验证通过，跳转到对应页面。此处以跳转到https://www.aliyun.com/页面为例
        window.location.href = 'https://www.aliyun.com/';
      } else {
        // 如果业务验证不通过，给出不通过提示。此处不通过提示为业务验证不通过！
        alert('业务验证不通过！');
      }
    },
    captchaVerifyCallback(captchaVerifyParam) {
      this.form.captchaVerifyParam = captchaVerifyParam;
      this.getCode(captchaVerifyParam);
    },
    getCode() {
      if (this.countdown === 0 && this.cardStatus) {
        window.location.reload();
      } else if (this.form.username && this.form.captchaVerifyParam) {
        if (this.isValidChinesePhoneNumber(this.form.username)) {
          getCodeImg(this.form.username, this.form.captchaVerifyParam).then(res => {
            if (res.code === 200) {
              this.$message.success("验证码发送成功")
              this.showCodeDialog();
              this.cardStatus = true;
              this.startCountdown();
            }
          })
        }
      }
    },
    isValidChinesePhoneNumber(phoneNumber) {
      const reg = /^1[3-9]\d{9}$/;
      return reg.test(phoneNumber);
    },
    startCountdown() {
      this.countdown = 60; // 设置倒计时秒数，例如60秒
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
        } else {
          clearInterval(this.intervalId);
          this.countdown = 0;
        }
      }, 1000);
    },
    handleLogin() {
      // 将验证码输入框的值赋给 form.code
      // this.form.code = this.codeInputs.join('');
      //
      // if (!this.form.code || this.form.code.length !== 6) {
      //   this.$message.error('请输入6位验证码');
      //   return;
      // }
      if (this.fullCode.length !== 6) {
        this.$message.error('请输入6位验证码');
        return;
      }

      const loading2 = this.$loading({
        lock: true,
        text: '加载中，请稍等',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      setTimeout(() => {
        loading2.close();
      }, 10000);
      this.loading = true;
      this.$store.dispatch("Login", this.form).then(() => {
        getLoanAppDetail().then(res => {
          if (res.code === 601) {
            this.$router.push({
              path: '/submit',
              query: {
                enterpriseId: this.type,
              }
            }).catch(err => {
              console.log(err)
            });
          } else if (res.code === 200) {
            this.$router.push("/finish").catch(err => {
              console.log(err)
            });
          }
          loading2.close();
        })
      }).catch(() => {
        this.loading = false;
        loading2.close();
      }).finally(() => {
        this.codeDialogVisible = false;
        // 重置验证码输入框
        this.codeInputs = ['', '', '', '', '', ''];
      });
    },
    openInfo() {
      this.open = true;
    },
    // 处理验证码输入
    handleCodeInput(index) {
      if (this.codeInputs[index].length === 1 && index < 5) {
        this.$nextTick(() => {
          this.$refs.codeInputs[index + 1].focus()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  //height: calc(100% - 88px);
  height: 100%;
}

.code-dialog {
  ::v-deep .el-dialog {
    border-radius: 12px;
    overflow: hidden;
  }

  ::v-deep .el-dialog__body {
    padding: 0;
  }
}

.code-dialog-content {
  padding: 20px;
  text-align: center;
  border-radius: 12px;
}

.code-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.code-input {
  position: relative;
  margin-bottom: 20px;

  input {
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.code-display {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  span {
    width: 40px;
    height: 40px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }
}

.code-tip {
  font-size: 14px;
  color: #909399;
  margin-bottom: 20px;
}

.code-submit-btn {
  width: 100%;
  height: 40px;
}


.background-div {
  height: 358px;
  background: url(../assets/images/background.png) no-repeat center center;
  background-size: cover;
  padding: 20px;
}

.title {
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 20px;
}

.el-card {
  width: 100%;
  margin: 0 auto;
}

.card-top {
  font-size: 14px;
  color: #3F434E;
  text-align: center;
}

.card-center {
  font-size: 36px;
  color: #3F434E;
  text-align: center;
  margin: 8px 0;
}

.card-bottom {
  font-size: 12px;
  color: #E47470;
  text-align: center;
}

.apply {
  font-size: 14px;
  color: #FFFFFF;
  margin: 10px 0;
}

.status {
  text-align: center;
}

.login-form {
  width: 100%;
  padding: 20px 20px 5px 20px;
  margin: 0 auto;
}

.fixed-bottom {
  position: relative;
  left: 0;
  bottom: 0;
  height: 88px;
  width: 100%;
  box-sizing: content-box;
  background: #ffffff;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.06);
  border-radius: 24px 24px 0 0;
  z-index: 200;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-column-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ::v-deep div {
    font-size: 18px;
    color: #333333;
  }
}

.info {
  font-size: 12px;
  color: #5A9CF8;
  float: right;
  cursor: pointer;
}

.custom-button {
  border: 1px solid #5A9CF8;

  ::v-deep span {
    color: #5A9CF8;
  }
}

::v-deep #aliyunCaptcha-window-embed {
  z-index: 0 !important;
}
</style>
